import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  CafePayment,
  Coffinity,
  Coupon,
  Detail,
  FeedDetail,
  FeedRegister,
  FindEmail,
  FindPassword,
  FindPassword2,
  JoinStep1,
  JoinStep2,
  Main,
  MainBest,
  MainNew,
  Order,
  OrderCart,
  OrderDetail,
  Root,
  TalkDetail,
  TalkEdit,
  TalkRegister,
  Search,
  SearchDetail,
  Setting,
  Mypage,
  EditPassword,
  UseBlock,
  EditInfo,
  EditPhone,
  EditAddress,
  MyCoupon,
  Point,
  Like,
  CafePaymentResult,
  Market,
  MyCafeOrder,
  MyCafeOrderDetail,
  Notice,
  NoticeDetail,
  CofficQna,
  QnaRegister,
  MarketList,
  MarketDetail,
  Shop,
  OrderCancel,
  MyMarketOrder,
  MyMarketOrderDetail,
  OrderDelivery,
  OrderCancelDetail,
  OrderMarketCancel,
  OrderMarketQna,
  Reviews,
  OrderCafeReview,
  OrderMarketReview,
  Cart,
  SubscribePayment,
  Comment,
  CoffeeTalk,
  Subscribe,
  SubscribeDetail,
  EditSubscribeAddress,
  SubscribeResult,
  NormalPayment,
  CoffikTest,
  TestPage,
  TestResult,
  NewPassword,
  SubscribeVerification,
  SubscribeCard,
  NotFound,
  SubscribeOrder,
  SubscribeOrderDetail,
  FeedEdit,
  Kakao,
  Apple,
  NormalResult,
  Service,
  Private,
  SetPayment,
  Download,
  Vr
} from "../pages";
import { SWRConfig } from "swr";
import swrConfig from "../utils/swrConfig";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../styles/global-styles";
import theme from "../utils/theme";
import WebRoute from "../components/routes/WebRoute";
import HelmetComponent from "../components/HelmetComponent";
import PrivateRoute from "../components/routes/PrivateRoute";
import auth from "../utils/auth";

const varUA = navigator.userAgent;

const App = () => {
  const pathname = window.location.pathname;

  useEffect(() => {
    checkForAccess();
    if (
      pathname === "/coffik/result" ||
      pathname === "/cafe/purchase/result" ||
      pathname === "/market/normal/result"
    ) {
      return;
    } else {
      if (
        window.localStorage.getItem("lat") &&
        window.localStorage.getItem("lng") &&
        varUA.match("coffik/iOS") != null
      ) {
        //window.localStorage.removeItem("lat");
        //window.localStorage.removeItem("lng");
      }
    }

    setTimeout(() => {
      handleLocation();
    });
  }, []);

  const checkForAccess = () => {
    if (auth.getToken() !== null) {
      if (varUA.match("coffik/Android") !== null) {
        window.coffik.postMessage(
          JSON.stringify({ type: "autoLogin", token: auth.getToken() })
        );
      } else if (varUA.match("coffik/iOS") !== null) {
        window.webkit.messageHandlers.autoLoginHandler.postMessage(
          auth.getToken()
        );
      } else if (varUA.match("coffik15/Android") !== null) {
        window.coffik.autoLogin(auth.getToken());
      }
    }
  };

  const handleLocation = () => {
    try {
      if (varUA.match("coffik/Android") != null || varUA.match("coffik15/Android") != null) {
        window.coffik.postMessage(JSON.stringify({ type: "getlatlng" }));
      }
    } catch (error) {}
  };

  return (
    <SWRConfig
      value={{
        ...swrConfig,
        revalidateOnFocus: false,
        errorRetryCount: 0,
      }}
    >
      <ThemeProvider theme={theme}>
        <HelmetComponent />
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            {/* 404페이지 */}
            <Route
              path="/*" //@ts-ignore
              element={<WebRoute element={<NotFound />} />}
            />

            {/* 다운로드 */}
            <Route
              path="/coffik/app/download"
              element={<WebRoute element={<Download />} />}
            />

            {/* 로그인 */}
            <Route path="/" element={<WebRoute element={<Root />} />} />

            {/* 회원가입 */}
            <Route
              path="/join/step1"
              element={<WebRoute element={<JoinStep1 />} />}
            />
            <Route
              path="/join/step2"
              element={<WebRoute element={<JoinStep2 />} />}
            />

            {/* SNS로그인 */}
            <Route
              path="/app/login/kakao"
              element={<WebRoute element={<Kakao />} />}
            />
            <Route
              path="/app/login/apple"
              element={<WebRoute element={<Apple />} />}
            />

            {/* 커픽테스트 */}
            <Route
              path="/coffik/test"
              element={<PrivateRoute element={<CoffikTest />} />}
            />
            <Route
              path="/coffik/test/:number"
              element={<PrivateRoute element={<TestPage />} />}
            />
            <Route
              path="/coffik/result"
              element={<PrivateRoute element={<TestResult />} />}
            />

            {/* 아이디 찾기 */}
            <Route
              path="/find/email"
              element={<WebRoute element={<FindEmail />} />}
            />

            {/* 비밀번호 찾기 */}
            <Route
              path="/find/password"
              element={<WebRoute element={<FindPassword />} />}
            />
            <Route
              path="/find/password2"
              element={<WebRoute element={<FindPassword2 />} />}
            />
            <Route
              path="/find/password/new"
              element={<WebRoute element={<NewPassword />} />}
            />

            {/* 메인 */}
            <Route path="/main" element={<WebRoute element={<Main />} />} />
            <Route
              path="/main/best"
              element={<WebRoute element={<MainBest />} />}
            />
            <Route
              path="/main/new"
              element={<WebRoute element={<MainNew />} />}
            />

            {/* 커피니티 */}
            <Route
              path="/coffinity"
              element={<WebRoute element={<Coffinity />} />}
            />
            <Route
              path="/coffinity/:type"
              element={<WebRoute element={<Coffinity />} />}
            />
            <Route
              path="/coffinity/feed/:id"
              element={<PrivateRoute element={<FeedDetail />} />}
            />
            <Route
              path="/feed/register"
              element={<PrivateRoute element={<FeedRegister />} />}
            />
            <Route
              path="/feed/edit/:id"
              element={<PrivateRoute element={<FeedEdit />} />}
            />
            <Route
              path="/coffinity/talk/:id"
              element={<PrivateRoute element={<TalkDetail />} />}
            />
            <Route
              path="/talk/register"
              element={<PrivateRoute element={<TalkRegister />} />}
            />
            <Route
              path="/talk/edit/:id"
              element={<PrivateRoute element={<TalkEdit />} />}
            />

            {/* 주문 */}
            <Route path="/order" element={<WebRoute element={<Order />} />} />
            <Route
              path="/cafe/:id"
              element={<WebRoute element={<Detail />} />}
            />
            <Route
                path="/cafe/vr/:id"
                element={<WebRoute element={<Vr />} />}
            />
            <Route
              path="/cafe/order/:cafe/:id"
              element={<PrivateRoute element={<OrderDetail />} />}
            />
            <Route
              path="/cafe/coupon/:id"
              element={<WebRoute element={<Coupon />} />}
            />
            <Route
              path="/cafe/order/cart/:cafe"
              element={<PrivateRoute element={<OrderCart />} />}
            />
            <Route
              path="/cafe/payment"
              element={<PrivateRoute element={<CafePayment />} />}
            />
            <Route
              path="/cafe/purchase/result"
              element={<PrivateRoute element={<CafePaymentResult />} />}
            />

            {/* 마켓 */}
            <Route path="/market" element={<WebRoute element={<Market />} />} />
            <Route
              path="/market/list/:type"
              element={<WebRoute element={<MarketList />} />}
            />
            <Route
              path="/market/detail/:id"
              element={<WebRoute element={<MarketDetail />} />}
            />
            <Route
              path="/market/shop/:id"
              element={<WebRoute element={<Shop />} />}
            />
            <Route
              path="/market/payment/subscribe"
              element={<PrivateRoute element={<SubscribePayment />} />}
            />
            <Route
              path="/market/subscribe/result"
              element={<PrivateRoute element={<SubscribeResult />} />}
            />
            <Route
              path="/market/payment/normal"
              element={<PrivateRoute element={<NormalPayment />} />}
            />
            <Route
              path="/market/normal/result"
              element={<PrivateRoute element={<NormalResult />} />}
            />
            <Route
              path="/market/payment/set"
              element={<PrivateRoute element={<SetPayment />} />}
            />

            {/* 검색페이지 */}
            <Route path="/search" element={<WebRoute element={<Search />} />} />
            <Route
              path="/search/:search"
              element={<WebRoute element={<SearchDetail />} />}
            />
            <Route
              path="/search/:search/:type"
              element={<WebRoute element={<SearchDetail />} />}
            />

            {/* 마이페이지 */}
            <Route
              path="/mypage"
              element={<PrivateRoute element={<Mypage />} />}
            />
            <Route
              path="/mypage/setting"
              element={<PrivateRoute element={<Setting />} />}
            />
            <Route
              path="/mypage/editinfo"
              element={<PrivateRoute element={<EditInfo />} />}
            />
            <Route
              path="/mypage/editPw"
              element={<PrivateRoute element={<EditPassword />} />}
            />
            <Route
              path="/mypage/use_block"
              element={<PrivateRoute element={<UseBlock />} />}
            />
            <Route
              path="/mypage/editPhone"
              element={<PrivateRoute element={<EditPhone />} />}
            />
            <Route
              path="/mypage/editAddress"
              element={<PrivateRoute element={<EditAddress />} />}
            />
            <Route
              path="/mypage/coupon"
              element={<PrivateRoute element={<MyCoupon />} />}
            />
            <Route
              path="/mypage/point"
              element={<PrivateRoute element={<Point />} />}
            />
            <Route
              path="/mypage/like"
              element={<PrivateRoute element={<Like />} />}
            />
            <Route
              path="/mypage/cafe"
              element={<PrivateRoute element={<MyCafeOrder />} />}
            />
            <Route
              path="/mypage/cafe/detail/:detailId"
              element={<PrivateRoute element={<MyCafeOrderDetail />} />}
            />
            <Route
              path="/mypage/market"
              element={<PrivateRoute element={<MyMarketOrder />} />}
            />
            <Route
              path="/mypage/market/detail/:detailId"
              element={<PrivateRoute element={<MyMarketOrderDetail />} />}
            />
            <Route path="/notice" element={<WebRoute element={<Notice />} />} />
            <Route
              path="/notice/:noticeId"
              element={<WebRoute element={<NoticeDetail />} />}
            />
            <Route
              path="/coffic/qna"
              element={<WebRoute element={<CofficQna />} />}
            />
            <Route
              path="/coffic/qna/register"
              element={<PrivateRoute element={<QnaRegister />} />}
            />
            <Route
              path="/order/cancel/:cancelId"
              element={<PrivateRoute element={<OrderCancel />} />}
            />
            <Route
              path="/order/market/cancel/:cancelId/:type/:productId"
              element={<PrivateRoute element={<OrderMarketCancel />} />}
            />
            <Route
              path="/order/cafe/review/:reviewId"
              element={<PrivateRoute element={<OrderCafeReview />} />}
            />
            <Route
              path="/order/cafe/review/:reviewId/:registerId"
              element={<PrivateRoute element={<OrderCafeReview />} />}
            />
            <Route
              path="/order/market/review/:reviewId"
              element={<PrivateRoute element={<OrderMarketReview />} />}
            />
            <Route
              path="/order/market/review/:reviewId/:registerId"
              element={<PrivateRoute element={<OrderMarketReview />} />}
            />
            <Route
              path="/order/delivery/:productId"
              element={<PrivateRoute element={<OrderDelivery />} />}
            />
            <Route
              path="/order/cancel/detail/:cancelId"
              element={<PrivateRoute element={<OrderCancelDetail />} />}
            />
            <Route
              path="/order/market/qna/:qnaId/:productId"
              element={<PrivateRoute element={<OrderMarketQna />} />}
            />
            <Route
              path="/mypage/reviews"
              element={<PrivateRoute element={<Reviews />} />}
            />
            <Route
              path="/mypage/comment"
              element={<PrivateRoute element={<Comment />} />}
            />
            <Route
              path="/mypage/coffeetalk"
              element={<PrivateRoute element={<CoffeeTalk />} />}
            />
            <Route
              path="/mypage/subscribe"
              element={<PrivateRoute element={<Subscribe />} />}
            />
            <Route
              path="/mypage/subscribe/:orderNumber/:detailId"
              element={<PrivateRoute element={<SubscribeDetail />} />}
            />
            <Route
              path="/mypage/subscribe/editAddress/:type"
              element={<PrivateRoute element={<EditSubscribeAddress />} />}
            />
            <Route
              path="/mypage/subscribe/verification"
              element={<PrivateRoute element={<SubscribeVerification />} />}
            />
            <Route
              path="/mypage/subscribe/card"
              element={<PrivateRoute element={<SubscribeCard />} />}
            />
            <Route
              path="/mypage/subscribe_order"
              element={<PrivateRoute element={<SubscribeOrder />} />}
            />
            <Route
              path="/mypage/subscribe_order/detail/:detailId"
              element={<PrivateRoute element={<SubscribeOrderDetail />} />}
            />
            {/* 장바구니 */}
            <Route
              path="/cart/:type"
              element={<PrivateRoute element={<Cart />} />}
            />

            {/* 약관 */}
            <Route
              path="/term/service"
              element={<WebRoute element={<Service />} />}
            />
            <Route
              path="/term/private"
              element={<WebRoute element={<Private />} />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </SWRConfig>
  );
};

export default App;
