import moment from "moment";
import React from "react";
import auth from "./auth";
import deliveryList from "../data/deliveryList.json";

export const mergeList = (data: any) => data?.flatMap((d: any) => d);

export const timerFunc = (
  intervalId: any,
  duration: number,
  setCodeError: (str: string) => void,
  setTimer: (str: string) => void,
  setCode: (str: string) => void
) => {
  let timer = duration;
  let m, s;

  m = Math.floor(timer / 60);
  s = Math.floor(timer % 60);
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  if (timer <= 0) {
    timer = 0;
    //@ts-ignore
    clearInterval(intervalId.current);
    setCodeError("인증 시간이 초과되었습니다.");
    setTimer("00:00");
    setCode("");
  } else {
    setTimer(m + ":" + s);
  }
};

export const handleLogout = () => {
  auth.clearAppStorage();
};

export const handleShare = (handleToast?: any) => {
  const url = window.location.href;
  const varUA = navigator.userAgent.toLowerCase();

  if (varUA.match("coffik15/android") != null) {
    window.coffik.share(url);
  } else if (varUA.indexOf("android") > -1) {
    try {
      //@ts-ignore
      window.coffik.postMessage(
        JSON.stringify({
          type: "share",
          text: url,
        })
      );
    } catch (error) {
      navigator.share({
        url: url,
      });
    }
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    try {
      //@ts-ignore
      window.webkit.messageHandlers.shareHandler.postMessage(
        JSON.stringify({
          all_text: url,
        })
      );
    } catch (error) {
      navigator.share({
        url: url,
      });
    }
  } else {
    handleCopy(url);
    handleToast && handleToast();
  }
};

export const handleShareTest = (id: number) => {
  const url = window.location.href + "?share=true&id=" + id;
  const varUA = navigator.userAgent.toLowerCase();

  if (varUA.match("coffik15/android") != null) {
    window.coffik.share(url);
  } else if (varUA.indexOf("android") > -1) {
    try {
      //@ts-ignore
      window.coffik.postMessage(
        JSON.stringify({
          type: "share",
          text: url,
        })
      );
    } catch (error) {
      navigator.share({
        url: url,
      });
    }
  } else if (
    varUA.indexOf("iphone") > -1 ||
    varUA.indexOf("ipad") > -1 ||
    varUA.indexOf("ipod") > -1
  ) {
    try {
      //@ts-ignore
      window.webkit.messageHandlers.shareHandler.postMessage(
        JSON.stringify({
          all_text: url,
        })
      );
    } catch (error) {
      navigator.share({
        url: url,
      });
    }
  } else {
    handleCopy(url);
  }
};

export const toNumber = (value: string) => {
  let el = value.replace(/[^0-9]/g, "");
  return el;
};

export const handlePhone = (value: string) => {
  return (value ? value : "").replace(
    /^(\d{2,3})(\d{3,4})(\d{4})$/,
    `$1-$2-$3`
  );
};

export const handleCard = (value: any) => {
  return value.match(/[0-9●]{1,4}/g)?.join("-");
};
export const handleBirth = (value: any) => {
  return value.match(/[0-9●]{1,6}/g)?.join("-");
};
export const handleDate = (value: any) => {
  return value.match(/[0-9●]{1,2}/g)?.join("/");
};

export const Dot = (value: number) => {
  let el = (value ? value : 0).toString();
  return el.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const Percent = (origin: number, price: number) => {
  let percent = ((price / origin) * 100).toFixed(0);
  return percent + "%";
};

export const handleImage = (image: string) => {
  return encodeURI(image)
    .replace(/ /g, "%20")
    .replace("(", "%28")
    .replace(")", "%29");
};

export const DotDate = (date: string) => {
  return moment(date).format("YYYY.MM.DD");
};

export const DotDateTime = (date: string) => {
  return moment(date).format("YYYY.MM.DD HH:mm");
};

export const DotDay = (date: string) => {
  const day = moment(date);
  let format = day.format("YYYY.MM.DD");
  let dayNum = "일";

  if (Number(day.day()) === 0) dayNum = "일";
  else if (Number(day.day()) === 1) dayNum = "월";
  else if (Number(day.day()) === 2) dayNum = "화";
  else if (Number(day.day()) === 3) dayNum = "수";
  else if (Number(day.day()) === 4) dayNum = "목";
  else if (Number(day.day()) === 5) dayNum = "금";
  else if (Number(day.day()) === 6) dayNum = "토";

  return format + ` (${dayNum}) `;
};

export const handleHoliday = (holidays: string) => {
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const day = moment().day();

  return holidays && holidays !== "" && holidays.includes(week[day]);
};

export const handleCopy = (text: string) => {
  var textarea = document.createElement("textarea");
  document.body.appendChild(textarea);
  textarea.value = text;
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export const handleBank = (code: string) => {
  for (const option of bankOptions) {
    if (option.value === code) return option.text;
  }
};

export const handleDelivery = (deliCompany: string) => {
  let Name = "";
  deliveryList?.filter(function (delivery) {
    if (deliCompany === delivery.value) {
      Name = delivery.name;
    }
  });
  return Name;
};

export const timeForToday = (value: string) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );

  if (betweenTime < 1) return "방금 전";
  if (betweenTime < 60) {
    return `${betweenTime}분 전`;
  }

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour < 24) {
    return `${betweenTimeHour}시간 전`;
  }

  const betweenTimeDay = Math.floor(betweenTime / 60 / 24);
  if (betweenTimeDay < 4) {
    return `${betweenTimeDay}일 전`;
  }

  return moment(value).format("YYYY-MM-DD");
};

export const Dday = (value: string) => {
  const today = moment(moment().format("YYYY-MM-DD") + " 00:00:00");
  const day = moment(moment(value).format("YYYY-MM-DD") + " 00:00:00");

  return day.diff(today, "days") === 0
    ? "D-Day"
    : "D" +
        (day.diff(today, "days") > 0
          ? "-" + day.diff(today, "days")
          : "+" + day.diff(today, "days") * -1);
};

export const CafeStatus = (status: number) => {
  if (status === 0) {
    return "접수 대기";
  } else if (status === 1) {
    return "제조중";
  } else if (status === 2) {
    return "픽업 대기";
  } else if (status === 3) {
    return "픽업 완료";
  } else if (status === 10) {
    return "결제 취소";
  } else {
    return "";
  }
};
export const MarketStatus = (status: number) => {
  if (status === 1) {
    return "결제 완료";
  } else if (status === 2) {
    return "상품 준비중";
  } else if (status === 3) {
    return "배송 대기";
  } else if (status === 4) {
    return "배송중";
  } else if (status === 5) {
    return "배송완료";
  } else if (status === 6) {
    return "구매확정";
  } else if (status === 10) {
    return "취소 신청";
  } else if (status === 11) {
    return "결제 취소";
  } else if (status === 12) {
    return "환불 신청";
  } else if (status === 13) {
    return "환불 완료";
  } else {
    return "";
  }
};
export const SubscribeOrderStatus = (status: number) => {
  if (status === 1) {
    return "결제 완료";
  } else if (status === 2) {
    return "상품 준비중";
  } else if (status === 3) {
    return "배송 대기";
  } else if (status === 4) {
    return "배송중";
  } else if (status === 5) {
    return "배송 완료";
  } else if (status === 6) {
    return "구매 확정";
  } else if (status === 11) {
    return "결제 취소";
  } else {
    return "";
  }
};
export const bankOptions = [
  { value: "002", text: "산업은행" },
  { value: "003", text: "기업은행" },
  { value: "004", text: "국민은행" },
  { value: "007", text: "수협" },
  { value: "011", text: "농협" },
  { value: "012", text: "지역농축협" },
  { value: "020", text: "우리은행" },
  { value: "023", text: "SC제일은행" },
  { value: "027", text: "한국씨티은행" },
  { value: "031", text: "대구은행" },
  { value: "032", text: "부산은행" },
  { value: "034", text: "광주은행" },
  { value: "035", text: "제주은행" },
  { value: "037", text: "전북은행" },
  { value: "039", text: "경남은행" },
  { value: "045", text: "새마을금고" },
  { value: "048", text: "신협" },
  { value: "050", text: "저축은행" },
  { value: "054", text: "HSBC은행" },
  { value: "055", text: "도이치은행" },
  { value: "057", text: "JP모간체이스은행" },
  { value: "060", text: "BOA은행" },
  { value: "061", text: "BNP파리바은행" },
  { value: "062", text: "중국공상은행" },
  { value: "064", text: "산림조합" },
  { value: "067", text: "중국건설은행" },
  { value: "071", text: "우체국" },
  { value: "081", text: "하나은행" },
  { value: "088", text: "신한은행" },
  { value: "089", text: "케이뱅크" },
  { value: "090", text: "카카오뱅크" },
  { value: "209", text: "유안타증권" },
  { value: "218", text: "KB증권" },
  { value: "227", text: "KTB투자증권" },
  { value: "238", text: "미래에셋대우" },
  { value: "240", text: "삼성증권" },
  { value: "243", text: "한국투자증권" },
  { value: "247", text: "NH투자증권" },
  { value: "261", text: "교보증권" },
  { value: "262", text: "하이투자증권" },
  { value: "263", text: "현대차증권" },
  { value: "264", text: "키움증권" },
  { value: "265", text: "이베스트투자증권" },
  { value: "266", text: "SK증권" },
  { value: "267", text: "대신증권" },
  { value: "269", text: "한화투자증권" },
  { value: "270", text: "하나금융투자" },
  { value: "278", text: "신한금융투자" },
  { value: "279", text: "DB금융투자" },
  { value: "280", text: "유진투자증권" },
  { value: "287", text: "메리츠종합금융증권" },
  { value: "288", text: "카카오페이증권" },
  { value: "290", text: "부국증권" },
  { value: "291", text: "신영증권" },
  { value: "292", text: "케이프투자증권" },
  { value: "294", text: "펀드온라인코리아" },
];

export const orderOptions = [
  {
    value: "recent",
    text: "최신 순",
  },
  {
    value: "distance",
    text: "거리 순",
  },
  {
    value: "popularity",
    text: "인기 순",
  },
];

export const mapOrderOptions = [
  {
    value: "distance",
    text: "거리 순",
  },
  {
    value: "hot",
    text: "인기 순",
  },
];

export const smallOrderOptions = [
  {
    value: "recent",
    text: "최신 순",
  },
  {
    value: "popularity",
    text: "인기 순",
  },
];

export const marketOptions = [
  {
    value: "hot",
    text: "인기 순",
  },
  {
    value: "price",
    text: "가격 낮은 순",
  },
];

export const talkOptions = [
  {
    value: "프리톡",
    text: "프리톡",
  },
  {
    value: "레시피",
    text: "레시피",
  },
  {
    value: "추천/질문",
    text: "추천/질문",
  },
];

export const reportOptions = [
  "홍보 및 스팸성",
  "욕설 및 혐오 표현",
  "불법적인 정보",
  "개인정보 노출 및 저작권 침해",
  "음란물",
  "기타",
];

export const termSetting = [
  {
    title: "서비스 이용약관",
    type: "service",
  },
  {
    title: "위치 기반 서비스 이용약관",
    type: "place",
  },
  {
    title: "개인정보 처리방침",
    type: "privacy",
  },
];
export const userSetting = [
  {
    title: "비밀번호 변경",
    history: "/mypage/editpw",
  },
  {
    title: "로그아웃",
    history: "",
  },
  {
    title: "회원 탈퇴",
    history: "",
  },
];
export const myCafeOrderOptions = [
  {
    value: "",
    text: "전체",
  },
  {
    value: "매장 이용",
    text: "매장 이용",
  },
  {
    value: "픽업",
    text: "픽업",
  },
];
export const myCafeOrderStatusOptions = [
  {
    value: "",
    text: "전체",
  },
  {
    value: "0",
    text: "접수 대기",
  },
  {
    value: "1",
    text: "제조중",
  },
  {
    value: "2",
    text: "픽업 대기",
  },
  {
    value: "3",
    text: "픽업 완료",
  },
  {
    value: "10",
    text: "결제 취소",
  },
];

export const myMarketOrderStatusOptions = [
  {
    value: "",
    text: "전체",
  },
  {
    value: "1",
    text: "결제 완료",
  },
  {
    value: "2",
    text: "상품 준비중",
  },
  {
    value: "3",
    text: "배송 대기",
  },
  {
    value: "4",
    text: "배송중",
  },
  {
    value: "5",
    text: "배송완료",
  },
  {
    value: "6",
    text: "구매확정",
  },
  {
    value: "10",
    text: "취소 신청",
  },
  {
    value: "11",
    text: "결제 취소",
  },
  {
    value: "12",
    text: "환불 신청",
  },
  {
    value: "13",
    text: "환불 완료",
  },
];
export const mySubscribeOrderStatusOptions = [
  {
    value: "",
    text: "전체",
  },
  {
    value: "1",
    text: "결제 완료",
  },
  {
    value: "2",
    text: "상품 준비중",
  },
  {
    value: "3",
    text: "배송 대기",
  },
  {
    value: "4",
    text: "배송중",
  },
  {
    value: "5",
    text: "배송완료",
  },
  {
    value: "6",
    text: "구매확정",
  },
  {
    value: "11",
    text: "결제 취소",
  },
];
